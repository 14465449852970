import React, { ReactNode, useRef, useState } from "react";
import { Layout } from "@100mslive/types-prebuilt";
import { isEmpty, set } from "lodash";
import ValuePill from "src/components/Common/ValuePill";
import {
  defaultConferencing,
  defaultLiveStreamingConferencing,
} from "src/data/defaultLayout";
import {
  AppAnalytics,
  getPriorityRolesArray,
  getVisibleRoles,
  isHLSViewer,
  isInternalRecorderRole,
} from "src/helpers";
import SettingsTitle from "src/pages/Template/SettingsTitle";
import { roleTemplateForPolicy } from "src/store/policyTemplate";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@100mslive/react-icons";
import {
  Box,
  Checkbox,
  Dropdown,
  Flex,
  Switch,
  Text,
} from "@100mslive/react-ui";
import TemplateConfigCard from "../../components/Cards/TemplateConfigCard";
import TextAndSubtext from "../../components/Common/TextAndSubtext";
import { policyType, roleType } from "../../types/policyTypes";

interface Props {
  roleName: string;
  updateLayout: ({ layout }: { layout: Layout }) => void;
  updateRole(roleName: string, key: string, value: unknown): unknown;
  policy: policyType;
  isOnboarding: boolean;
  templateConfig: boolean;
  layout: Layout;
}

// eslint-disable-next-line complexity
const SubscribeStrategy: React.FC<Props> = ({
  roleName,
  updateRole,
  updateLayout,
  layout,
  policy,
  isOnboarding = false,
  templateConfig = false,
}) => {
  const [open, setOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const subscribeError =
    !isHLSViewer(roleName) && !isInternalRecorderRole(roleName)
      ? (policy?.roles?.[roleName] as roleType)?.subscribeParams
          ?.subscribeToRoles?.length === 0
        ? "Warning: Please select at least one role."
        : ""
      : "";
  return (
    <Component
      cardRef={cardRef}
      templateConfig={templateConfig}
      isOnboarding={isOnboarding}
    >
      {!templateConfig ? (
        <Text variant="caption" css={{ c: "$textMedEmp", mt: "$10", mb: "$4" }}>
          Subscribed to
        </Text>
      ) : (
        <SettingsTitle
          title="Select other roles which should see the published tracks like audio, video, and screenshare from this role."
          hideTooltip={!templateConfig}
          text="Subscribe to"
          css={{ mb: "$4" }}
        />
      )}
      <Flex
        direction="column"
        css={{
          minWidth: "100%",
          position: "relative",
          mb: subscribeError ? "$4" : "0",
        }}
      >
        <Dropdown.Root open={open} modal={false} onOpenChange={setOpen}>
          <Dropdown.Trigger
            onClick={() => {
              if (isOnboarding) {
                AppAnalytics.track("btn.clicked", {
                  btnId: "subscribe.to.clicked",
                  componentId: "guided.onboarding.customize",
                });
              }
            }}
            asChild
            css={{
              r: "$1",
              w: "100%",
              mt: "$4",
              bg: "$surfaceLight",
              borderRadius: "$0",
            }}
          >
            <Flex
              justify="between"
              align="center"
              css={{
                bg: "$surfaceLight !important",
                p: "$5 $6",
                border: "1px solid $borderLight",
                borderRadius: "$0",
              }}
            >
              <Text variant="body2" css={{ c: "$textAccentDisabled" }}>
                Select roles
              </Text>
              <Box className="flex align-middle">
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Box>
            </Flex>
          </Dropdown.Trigger>

          <Dropdown.Content
            css={{
              w: `${
                cardRef?.current
                  ? Math.floor(
                      cardRef?.current?.offsetWidth / (templateConfig ? 1 : 2)
                    )
                  : 0
              }px`,
            }}
            align="end"
            className="bg-surface-light shadow-2xl px-0"
            sideOffset={10}
            onInteractOutside={() => setOpen(false)}
          >
            {getPriorityRolesArray(
              getVisibleRoles(Object.keys(policy?.roles))
            ).map((role: string) => {
              const subscribeToRolesSet = new Set(
                (
                  policy?.roles?.[roleName] as roleType
                )?.subscribeParams?.subscribeToRoles
              );
              const checked = subscribeToRolesSet.has(role);
              const onCheckedChange = () => {
                let arr = [];
                if (subscribeToRolesSet.has(role)) {
                  subscribeToRolesSet.delete(role);
                  arr = Array.from(subscribeToRolesSet);
                  if (arr.length === 0) {
                    delete layout.screens?.conferencing?.default;
                    const tempLayout = set(
                      layout,
                      "screens.conferencing.hls_live_streaming",
                      defaultLiveStreamingConferencing["hls_live_streaming"]
                    );
                    updateLayout({ layout: tempLayout });
                  }
                } else {
                  if (Array.from(subscribeToRolesSet).length === 0) {
                    delete layout.screens?.conferencing?.hls_live_streaming;
                    const tempLayout = set(
                      layout,
                      "screens.conferencing.default",
                      defaultConferencing["default"]
                    );
                    updateLayout({ layout: tempLayout });
                  }
                  subscribeToRolesSet.add(role);
                  arr = Array.from(subscribeToRolesSet);
                }
                updateRole(roleName, "subscribeParams.subscribeToRoles", arr);
              };
              return (
                <Dropdown.CheckboxItem
                  key={role}
                  onSelect={e => e.preventDefault()}
                  checked={checked}
                  onCheckedChange={onCheckedChange}
                  css={{ p: "$6" }}
                >
                  <Flex align="center">
                    <Checkbox.Root
                      id={role}
                      checked={checked}
                      css={{
                        bg: "$textMedEmp",
                        borderColor: "$textMedEmp",
                        '&[data-state="checked"]': {
                          bg: "$textMedEmp",
                        },
                      }}
                    >
                      <Checkbox.Indicator css={{ c: "$secondaryDark" }}>
                        <CheckIcon width={16} height={16} />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                    <Text
                      variant="caption"
                      css={{ ml: "$6", c: "$textMedEmp" }}
                    >
                      {role}
                    </Text>
                  </Flex>
                </Dropdown.CheckboxItem>
              );
            })}
          </Dropdown.Content>
        </Dropdown.Root>
        <Flex css={{ flexWrap: "wrap" }}>
          {(
            policy?.roles?.[roleName] as roleType
          )?.subscribeParams?.subscribeToRoles?.map((role: string) => (
            <ValuePill
              key={role}
              content={role}
              onClick={() => {
                const arr = (
                  policy?.roles?.[roleName] as roleType
                )?.subscribeParams?.subscribeToRoles.filter(
                  (roleInArray: string) => roleInArray !== role
                );
                updateRole(roleName, "subscribeParams.subscribeToRoles", arr);
              }}
            />
          ))}
        </Flex>
        {subscribeError ? (
          <Flex css={{ position: "absolute", bottom: -24 }}>
            <Text css={{ c: "$error" }} variant="caption">
              {subscribeError}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      {templateConfig ? (
        <Flex direction="column" css={{ minWidth: "100%", mt: "$10" }}>
          <SettingsTitle
            title="When this flag is turned on, one or more remote video tracks will be muted automatically when the network condition worsens. Such tracks will be marked as degraded. When the network condition improves, the degraded tracks will automatically be unmuted."
            hideTooltip={!templateConfig}
            text="Subscribe Degradation"
          />
          <Flex css={{ mt: "$6" }} direction="row" align="center">
            <Switch
              onCheckedChange={(e: boolean) => {
                const degradeObject = !e
                  ? {}
                  : {
                      ...roleTemplateForPolicy.subscribeParams
                        .subscribeDegradation,
                    };
                updateRole(
                  roleName,
                  "subscribeParams.subscribeDegradation",
                  degradeObject
                );
              }}
              checked={
                !isEmpty(
                  (policy?.roles?.[roleName] as roleType)?.[
                    "subscribeParams"
                  ]?.["subscribeDegradation"]
                )
              }
              disabled={false}
            />

            <Text variant="caption" css={{ c: "$textDisabled", ml: "$8" }}>
              {!isEmpty(
                (policy?.roles?.[roleName] as roleType)?.["subscribeParams"]?.[
                  "subscribeDegradation"
                ]
              )
                ? "Enabled"
                : "Disabled"}
            </Text>
          </Flex>
        </Flex>
      ) : null}
    </Component>
  );
};

export default SubscribeStrategy;

export const Component = ({
  templateConfig,
  children,
  isOnboarding,
  cardRef,
}: {
  templateConfig: boolean;
  children: ReactNode[] | ReactNode | undefined;
  isOnboarding: boolean;
  cardRef?: React.RefObject<HTMLDivElement>;
}) => {
  if (!templateConfig) {
    return (
      <Flex
        direction="column"
        css={{ minWidth: "100%", mt: "$12" }}
        ref={cardRef}
        id="SubscribeStrategies"
      >
        <TextAndSubtext
          text="Subscribe Strategy"
          classNameForText="config-subheading"
          textVariant={isOnboarding ? "md" : "h6"}
          subText="Used to determine which roles will receive audio, video, and screenshare
        from this role"
          subTextVariant="sm"
          flexGap="$4"
        />
        {children}
      </Flex>
    );
  }
  return (
    <TemplateConfigCard
      id="SubscribeStrategies"
      classNameForText="config-subheading"
      text="Subscribe Strategy"
      subtitle="Subscribe strategies will be used to determine what all roles, this role can subscribe to."
      rightComponent={children as unknown as JSX.Element}
      cardRef={cardRef}
    />
  );
};
