import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckSubdomain } from "src/hooks/useCheckSubdomain";
import { ArrowRightIcon, LayoutIcon } from "@100mslive/react-icons";
import { Text } from "@100mslive/react-ui";
import MainDialog from "./MainDialog";
import {
  addSubdomainToExistingTemplate,
  CHECK_SUBDOMAIN_RESET,
} from "../../actions/RolesActions";
import { API_CALL_STATE } from "../../constants";
import { createErrorMessage } from "../../validations";
import Credential from "../Common/Credential";

const AddSubdomainModal = ({
  policyId,
  appType,
  trigger = false,
  setTrigger,
}) => {
  const dispatch = useDispatch();
  const addSubdomainToApiTemplateStatus = useSelector(
    state => state.roles.addSubdomainToApiTemplateStatus
  );
  const [subdomainName, setSubdomainName] = useState("");
  // custom hook to check subdomain
  const {
    error: subdomainError,
    status: checkSubdomainStatus,
    changeHandler: handleSubdomainNameChange,
  } = useCheckSubdomain({
    setSubdomain: subdomain => {
      setSubdomainName(subdomain);
    },
    subdomain: subdomainName,
  });

  useEffect(() => {
    return () => dispatch({ type: CHECK_SUBDOMAIN_RESET });
  }, [dispatch]);

  return (
    <MainDialog
      loading={checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS}
      title="Add Prebuilt"
      variant="primary"
      Icon={<LayoutIcon height={32} width={32} />}
      disableButton={
        checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS || subdomainError
      }
      subtitle={
        <Text
          variant="body2"
          css={{
            fontWeight: "$regular",
            c: "$textMedEmp",
            mb: "$14",
          }}
        >
          {`Enter a subdomain where your Prebuilt will be deployed.\n`}
          <a
            href={`${process.env.REACT_APP_WEBSITE_URL}docs/get-started/v2/get-started/prebuilt/overview`}
            rel="noreferrer noopener"
            target="_blank"
          >
            <Text
              variant="body2"
              css={{ c: "$primaryLight", fontWeight: "$regular", mb: "$14" }}
              as="span"
            >
              Learn what’s possible with prebuilt{" "}
              <ArrowRightIcon height={14} width={14} />
            </Text>
          </a>
        </Text>
      }
      content={
        <>
          <Credential
            showClipboard={false}
            css={{ w: "100%", mb: !subdomainError ? "$14" : "0" }}
            error={subdomainError}
            passEvent={true}
            placeholder="e.g. mysubdomain"
            success={
              !(
                checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS ||
                subdomainError
              )
            }
            onChangeHandler={e => {
              const input = e.target.value?.trim()?.toLowerCase();
              handleSubdomainNameChange(
                input,
                "subdomain.rename.success",
                "add.subdomain.template.config"
              );
            }}
            loading={
              addSubdomainToApiTemplateStatus ||
              checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS
            }
            hideLabel
            title=""
            rightComp={
              <Text
                css={{
                  c: "$textAccentDisabled",
                }}
              >
                {process.env.REACT_APP_VERCEL_BASE_DOMAIN}
              </Text>
            }
          />
          <Text
            variant="caption"
            css={{
              fontWeight: "$regular",
              marginTop: "$4",
              c: "$error",
            }}
          >
            {createErrorMessage(subdomainError)}
          </Text>
        </>
      }
      open={trigger}
      buttonText="Add Prebuilt"
      mainFunction={() => {
        dispatch(
          addSubdomainToExistingTemplate(
            policyId,
            subdomainName + process.env.REACT_APP_VERCEL_BASE_DOMAIN,
            appType
          )
        );
        setTrigger(false);
      }}
      onOpenChange={() => setTrigger(prev => !prev)}
    />
  );
};

export default AddSubdomainModal;
